.navbar {
  height: 80px;
  background-color: #0D4D4D;
  border-bottom: 1px solid darkgray;
}
.navbar-brand {
  font-size: 1.5em;
  user-select: none;
}
.navbar a {
  margin-left: 16px;
  font-size: 1.35em;
  color: rgba(255, 255, 255, 0.5);
}
.navbar a:hover {
  color: rgba(255, 255, 255, 0.75);
}
.social-icon {
  font-size: 2.4em;
}
.registered {
  margin-left: 3px;
  margin-bottom: -2px;
  font-size: 0.4em;
}