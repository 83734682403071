.card-deck {
  margin-top: 30px;
  margin-bottom: 30px;
}
.card {
  border: 1px solid darkgray;
}
.card-header {
  text-align: center;
} 
.card-title {
  text-align: center;
}
.card-icon-wrapper {
  position: relative;
  float: left;
  height: 100%;
  width: 20%;
}
.font-icon {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-height: 100%;
}
.card-image {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-height: 100%;
  width: 125px;
}
.card-text-wrapper {
  position: relative;
  float: right;
  height: 100%;
  width: 78%;
  padding: 12px;
}
.card-text {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}